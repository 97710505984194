import React from 'react';
import './Voucher.css';
import gutschein from "../../images/gutschein.webp";
import {Cookies} from "react-cookie-consent";

const Voucher = () => {
    const handleClick = () => {
        const cookieConsent = Cookies.get('CookieConsent');
        if (cookieConsent) {
            window.gtag('event', 'Link', {
                send_to: 'G-XTXWZHE5SQ',
                event_category: 'Link',
                event_label: 'Geklickt',
            });
        }
    };

    return (
        <section id="sparen" className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 mb-5">
                        <img src={gutschein} className="img-fluid" alt="10% Gutschein bei Kauf einer Osmoseanlage" />
                    </div>
                    <div className="col-lg-7">
                        <h2>Jetzt 10 % sparen – Ihr exklusiver Gutschein für reines Trinkwasser!</h2>
                        <p>Sichern Sie sich jetzt 10 % Rabatt auf Ihre Bestellung und genießen Sie die Vorteile eines hochwertigen Osmose-Systems für gesundes Trinkwasser! Gemeinsam mit unserem Partner Wasserhaus bieten wir allen Interessenten die Möglichkeit, reines, gefiltertes Wasser zu einem unschlagbaren Preis zu erleben.</p>

                        <p className="mb-3"><b>So einfach geht's:</b></p>
                        <ul>
                            <li>Besuchen Sie unseren <a href="https://wasserhaus.eu/produkte/wasserfilter/umkehrosmoseanlagen.html" title="Partner Wasserhaus besuchen" target="_blank" rel="noreferrer" onClick={handleClick}>Partner Wasserhaus</a> und wählen Sie das Osmose-System, das zu Ihnen passt.</li>
                            <li>Legen Sie das gewünschte Produkt in den Warenkorb.</li>
                            <li>Geben Sie im Warenkorb den Gutscheincode <b>"NEM10PRO"</b> ein.</li>
                            <li>Freuen Sie sich über 10 % Rabatt auf Ihren Einkauf und genießen Sie schon bald ultrareines Wasser bei Ihnen zu Hause!</li>
                        </ul>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Voucher;