import React, { useEffect, useState } from 'react';
import CookieConsent, {Cookies} from "react-cookie-consent";
import './CookieConsent.css';

const Cookie = () => {

    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const cookieConsent = Cookies.get('CookieConsent');
        if (cookieConsent === 'true') {
            handleAccept(); // Google Tag Manager initialisieren
        } else {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-XTXWZHE5SQ';
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', 'G-XTXWZHE5SQ', { 'anonymize_ip': true });
        window.gtag('config', 'AW-1009122341', { 'anonymize_ip': true });

        Cookies.set('CookieConsent', 'true', { expires: 30 });
        Cookies.set('GoogleAnalytics', 'true', { expires: 30 });

        //window.location.reload();
        window.gtag('event', 'accept_cookie', {
            event_category: 'cookie_consent',
            event_label: 'User accepted cookies',
            value: 1
        });

        setShowBanner(false);
    };

    const handleDecline = () => {
        setShowBanner(false);
    };

    return (
        <div>
            {showBanner && (
            <div className="cookie-consent-wrapper">
                <CookieConsent
                    onAccept={handleAccept}
                    onDecline={handleDecline}
                    enableDeclineButton
                    buttonText="Zustimmen"
                    declineButtonText="Ablehnen"
                    disableButtonStyles="true"
                    disableStyles="true"
                    overlay
                    flipButtons
                >
                    Wir verwenden Cookies, um unsere Webseite für Dich optimal zu gestalten, fortlaufend zu verbessern und die Zugriffe auf unsere Website zu analysieren. Du kannst in unserer <a href="https://www.nahrungsergaenzungsmittel-tipps.de/datenschutzerklaerung/" target="_blank" rel="noreferrer">Datenschutzerklärung</a> mehr darüber erfahren, welche Cookies wir verwenden
                </CookieConsent>
            </div>
        )}
        </div>
    );
}

export default Cookie;