import React from "react";
import {
    MDBCarousel,
    MDBCarouselItem,
    MDBContainer,
    MDBRow,
    MDBCol,
} from "mdb-react-ui-kit";

export default function App() {
    return (
        <MDBContainer className="my-5">
            <h2 className="text-center mb-3 mt-5">Testmonials</h2>
            <MDBCarousel showControls dark>
                <MDBCarouselItem className="active text-center" itemId={'1'}>
                    <MDBRow className="d-flex justify-content-center">
                        <MDBCol lg="8">
                            <h5 className="mb-3">Markus S.</h5>
                            <p className="text-muted">
                                <i className="bi bi-quote"></i>
                                Seitdem wir die Osmoseanlage installiert haben, schmeckt unser Leitungswasser
                                endlich wieder wie frisches Quellwasser. Ich hätte nie gedacht, dass der Unterschied
                                so groß sein könnte. Eine absolute Empfehlung für jeden Haushalt!
                            </p>
                        </MDBCol>
                    </MDBRow>
                    <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                    </ul>
                </MDBCarouselItem>

                <MDBCarouselItem className="text-center" itemId={2}>
                    <MDBRow className="d-flex justify-content-center">
                        <MDBCol lg="8">
                            <h5 className="mb-3">Anna K.</h5>
                            <p className="text-muted">
                                <i className="bi bi-quote"></i>
                                Unsere Familie ist begeistert von der Osmoseanlage. Die Wasserqualität ist deutlich besser und wir merken es vor allem beim Tee und Kaffee. Kein Kalk mehr im Wasserkocher und alles schmeckt viel intensiver. Eine lohnende Investition dank dem 10% Gutschein.
                            </p>
                        </MDBCol>
                    </MDBRow>
                    <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-half"></i></li>
                    </ul>
                </MDBCarouselItem>

                <MDBCarouselItem className="text-center" itemId={3}>
                    <MDBRow className="d-flex justify-content-center">
                        <MDBCol lg="8">
                            <h5 className="mb-3">Thomas R.</h5>
                            <p className="text-muted">
                                <i className="bi bi-quote"></i>
                                Ich war anfangs skeptisch, ob eine Osmoseanlage wirklich notwendig ist. Doch seit der Anschaffung merke ich, wie viel reiner unser Wasser ist. Auch unser Aquarium profitiert davon. Die Fische sind aktiver und gesünder.
                            </p>
                        </MDBCol>
                    </MDBRow>
                    <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-half"></i></li>
                    </ul>
                </MDBCarouselItem>

                <MDBCarouselItem className="text-center" itemId={4}>
                    <MDBRow className="d-flex justify-content-center">
                        <MDBCol lg="8">
                            <h5 className="mb-3">Laura W.</h5>
                            <p className="text-muted">
                                <i className="bi bi-quote"></i>
                                Endlich kalkfreies Wasser! Unsere Kaffeemaschine und der Wasserkocher danken es uns. Die Osmoseanlage hat unsere Erwartungen übertroffen und wir genießen täglich frisches, sauberes Wasser. Sehr zu empfehlen!
                            </p>
                        </MDBCol>
                    </MDBRow>
                    <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                    </ul>
                </MDBCarouselItem>

                <MDBCarouselItem className="text-center" itemId={5}>
                    <MDBRow className="d-flex justify-content-center">
                        <MDBCol lg="8">
                            <h5 className="mb-3">Peter und Sabine H.</h5>
                            <p className="text-muted">
                                <i className="bi bi-quote"></i>
                                Wir nutzen die Osmoseanlage jetzt seit sechs Monaten und sind rundum zufrieden. Der Unterschied im Geschmack des Wassers ist enorm.
                            </p>
                        </MDBCol>
                    </MDBRow>
                    <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                    </ul>
                </MDBCarouselItem>

                <MDBCarouselItem className="text-center" itemId={6}>
                    <MDBRow className="d-flex justify-content-center">
                        <MDBCol lg="8">
                            <h5 className="mb-3">Claudia M.</h5>
                            <p className="text-muted">
                                <i className="bi bi-quote"></i>
                                Die Osmoseanlage hat unser Leben verändert. Kein Schleppen von Wasserflaschen mehr und wir wissen genau, dass unser Trinkwasser frei von Schadstoffen ist. Ein tolles Produkt mit hervorragender Leistung.
                            </p>
                        </MDBCol>
                    </MDBRow>
                    <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-fill"></i></li>
                        <li><i className="bi bi-star-half"></i></li>
                    </ul>
                </MDBCarouselItem>

            </MDBCarousel>
            <p className="small text-center mt-5 opacity-75">Wir freuen uns über Ihre Meinung! <a href="mailto:kontakt@nahrungsergaenzungsmittel-tipps.de" title="E-Mail schreiben">Teilen Sie Ihre Erfahrungen</a> mit uns und helfen Sie anderen, die beste Entscheidung zu treffen.<br />
                Wie hat Ihnen die Anlage gefallen? Bewerten Sie mit bis zu 5 Sternen und lassen Sie uns wissen, wie zufrieden Sie sind. <br/>Vielen Dank für Ihr Feedback!</p>

        </MDBContainer>
    );
}